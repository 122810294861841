import React from 'react';

import style from './style';
import Tag from './tag';

export default ({
  tags,
  trim = false,
  onFilter = false,
  filterUrl,
  filterName,
}) => (
  <ul>
    <style jsx>{style}</style>

    {tags
      .sort(({ name: a }, { name: b }) => a.localeCompare(b))
      .map(({ name, slug }, index) => {
        if (trim !== false && trim <= index) {
          return null;
        }

        return (
          <li key={`tag-${slug}`}>
            <Tag
              slug={slug}
              onFilter={onFilter}
              filterUrl={filterUrl}
              filterName={filterName}
            >
              {name}
            </Tag>
          </li>
        );
      })}
  </ul>
);
